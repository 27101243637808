import React, { useState } from "react";
import axios from 'axios';

export const isBrowser = () => typeof window !== "undefined"

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [errors, setErrors] = useState({ });
  const [message, setMessage] = useState("");
  
  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    if (!confirmPassword) newErrors.confirmPassword = "Confirm Password is required";
    if (password !== confirmPassword) newErrors.confirmPassword = "Passwords do not match";
    if (!batchNumber) newErrors.batchNumber = "Batch Number is required";
    // if (batchNumber.length < 4) newErrors.batchNumber = "Batch Number must be 4 digits";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const user = {
      email: email,
      password: password,
      batchNumber: batchNumber,
      locale: process.env.GATSBY_LOCALE
    };

    axios.post(process.env.GATSBY_API_BASEURL + '/Users/register', user)
      .then(
        response => {         
          resetForm();
          setErrors({});
          setMessage("Your account has been created. Please use the form on your left to Login.")          
        }
      )      
      .catch(function (error) {
        if (error.response) {          
          setErrors({ error: error.response.data });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js          
          setErrors({ error: error.request });
        } else {
          // Something happened in setting up the request that triggered an Error          
          setErrors({ error: error.message });
        }
        setMessage("");
      });
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setBatchNumber('');
  }

  return (
    <div className="createUserForm">
      <h1>Create Account</h1>
      {errors.error && <p style={{ color: "#b03232" }}>{errors.error}</p>}
      {message && <p style={{ color: "#DBF9D7" }}>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          <span htmlFor="email">Email:</span>
          <input
            type="email"
            id="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p style={{ color: "#b03232" }}>{errors.email}</p>}
        </label>

        <label htmlFor="password">
          <span htmlFor="password">Password:</span>
          <input
            type="password"
            id="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p style={{ color: "#b03232" }}>{errors.password}</p>}
        </label>

        <label htmlFor="confirmPassword">
          <span htmlFor="confirmPassword">Confirm Password:</span>
          <input
            type="password"
            id="confirmPassword"
            placeholder="confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {errors.confirmPassword && <p style={{ color: "#b03232" }}>{errors.confirmPassword}</p>}
        </label>

        <label htmlFor="batchNumber">
          <span htmlFor="confirmPassword">Batch Number:</span>
          <input
            type="text"
            id="batchNumber"
            placeholder="batch number"
            value={batchNumber}
            onChange={(e) => setBatchNumber(e.target.value)}
          />
          {errors.batchNumber && <p style={{ color: "#b03232" }}>{errors.batchNumber}</p>}
        </label>

        <button type="submit">Create Account</button>
      </form>
    </div>
  );
};

export default SignupForm;
