import React, { useState } from "react";
import { navigate } from "gatsby"
import axios from 'axios';

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // submit form
    const user = {
      email: email,
      password: password,
      locale: process.env.GATSBY_LOCALE
    };

    axios.post(process.env.GATSBY_API_BASEURL + '/Users/login', user)
      .then(
        response => {
          window.localStorage.setItem("token", response.data.token)
          navigate(`/`);
        }
      )
      .catch(function (error) {        
        if (error.response) {          
          setErrors({ error: "Invalid email or password" });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js          
          setErrors({ error: error.request });
        } else {
          // Something happened in setting up the request that triggered an Error          
          setErrors({ error: error.message });
        }
      });
    // const error = handleLogin(email, password)
    // .then(response=>{
    //   if (!error) {
    //     navigate(`/`);
    //   }
    //   else {
    //     setErrors({ error: error });
    //   }
    // });    
  };

  return (
    <div className="loginForm">
      <h1>Log in</h1>
      {errors.error && <p style={{ color: "#b03232" }}>{errors.error}</p>}
      <form onSubmit={handleSubmit}>
        <label htmlFor="password">
          <span htmlFor="email">Email:</span>
          <input
            type="email"
            id="email"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p style={{ color: "#b03232" }}>{errors.email}</p>}
        </label>


        <label htmlFor="password">
          <span htmlFor="password">Password:</span>
          <input
            type="password"
            id="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errors.password && <p style={{ color: "#b03232" }}>{errors.password}</p>}
        </label>

        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginForm;
