import React, { Component } from "react"
import { navigate } from "gatsby"
import LoginForm from "./login"
import SignupForm from "./signup.js"
import { isLoggedIn } from "../../services/auth"

class Auth extends Component {
  render() {
    if (isLoggedIn()) {
      navigate(`/`)
    }

    return (
      <div className="formWrapperOverlay">
        <div className="formWrapperOverlayInner">
          <div className="introText">
            <p>To view this website you will need to supply proof that you are a patient. This can be done by entering your <strong>batch number</strong> of your medication when creating an account.</p>
            <p>You will only need your <strong>batch number</strong> to create your account. Once you have created your account you will be able to login using your email and whatever password you entered, the <strong>batch number</strong> is only used to confirm the initial account creation.</p>
          </div>
          <div className="innerWrap">
            <LoginForm />
            <SignupForm />
          </div>
          <div className="outroText">
            <p className="copyright">
              © 2022 EUSA Pharma
            </p>
            <p className="datePrep">
              Date of prep: Jan 2023
            </p>
            <p className="number">
              GL-SIL-XXXXXXXXX
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Auth