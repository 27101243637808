import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Auth from "../components/User/auth"

const App = () => (
  <Layout locale={process.env.GATSBY_LOCALE}>
    <Router>      
      <Auth path="/user/auth" />      
    </Router>
  </Layout>
)

export default App